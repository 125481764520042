.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: auto;
}

.modal-content {
  max-width: 600px;
  margin: 200px auto 0 auto;
  background: #fff;
  border: 1px solid #eaeaea;
  overflow: visible;
  outline: none;
  padding: 30px;
}

.modal-content main {
  margin-top: 0;
}

.modal-content .modal-content-title h3 {
  font-size: 16px;
}

.modal-content .modal-content-title hr {
  margin-bottom: 30px;
}

.modal-content .modal-content-close {
  float: right;
  cursor: pointer;
}

.modal-content .modal-content-close:hover {
  opacity: 0.7;
}

.modal-content .btn {
  width: 100%;
}
