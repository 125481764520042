.sidebar-nav {
  width: 240px;
  background: #fff;
  box-shadow: 7px 50px 15px 0 rgba(69,90,100,.05);
}

.sidebar-logo {
  height: 50px;
  background-color: #26262b;
  box-shadow: 7px 0 15px 0 rgba(38,38,43,.4);
  margin-bottom: 30px;
}

.sidebar-logo h1 {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  line-height: 50px;
  text-align: center;
  margin: 0;
  transition: all .3s ease;
}

.sidebar-logo h1:hover {
  opacity: .7;
  cursor: pointer;
}

.sidebar-nav ul {
  list-style-type: none;
}

.sidebar-nav li a {
  padding: 10px 20px;
  display: block;
  color: #26262b;
  text-decoration: none;
  font-size: 15px;
  position: relative;
  border-left: 5px solid #fff;
}

.sidebar-nav li a:hover {
  background: #f7f7f7;
  border-left: 5px solid #f7f7f7;
}

.sidebar-nav li a.active {
  background: #eaeaea;
  border-left: 5px solid #04a9f5;
  font-weight: 700;
}

.sidebar-nav svg {
  margin-right: 15px;
  width: 24px!important;
}