
.pagetitle h1 {
  font-size: 24px;
  padding: 10px;
  margin-left: 58px;
  border-left: 1px solid #dedede;
  display: inline-block;
  font-weight: 400;
}

.pagetitle svg {
  position: absolute;
  font-size: 26px;
  margin: 12px 15px;
}