.no-article-image {
  border: 1px solid #eaeaea;
  text-align: center;
  height: 120px;
  cursor: pointer;
  transition: all .3s ease;
}
.no-article-image:hover {
  background-color: #f4f7fa;
}

.no-article-image p {
  line-height: 120px;
  color: #aaa;
  user-select: none;
}