*,
html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f7fa;
  color: #26262b;
  font-size: 15px;
}

img {
  max-width: 100%;
  max-height: 100%;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.divider {
  height: 1px;
  background-color: #dedede;
  width: 100%;
}

.card {
  box-shadow: 0 10px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-color: #fff;
}

.text-center {
  text-align: center;
}

.big-number {
  font-size: 50px;
  font-weight: 300;
  text-align: center;
  display: block;
}

table {
  border-collapse: collapse;
  width: 100%;
}

table img {
  max-height: 64px;
  border-radius: 3px;
}

table thead {
  font-weight: bold;
}

table tbody tr {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}

table.hover tbody tr {
  cursor: pointer;
}

table.hover tbody tr:hover td {
  background-color: #f6fafe;
  border-top: 1px solid #b8d6f4;
  border-bottom: 1px solid #b8d6f4;
}

table.hover tbody tr:active td {
  background: #e0f0ff;
  border-top: 1px solid #b8d6f4;
  border-bottom: 1px solid #b8d6f4;
}

table thead th,
table tbody td {
  text-align: left;
  padding: 10px;
}

.table-dropdown {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(69, 90, 100, 0.18);
  right: 0;
  padding: 10px;
  border-radius: 3px;
}
.table-dropdown ul {
  list-style-type: none;
}
.table-dropdown li {
  padding: 5px 10px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background: #04a9f5;
  border: none;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 600;
  box-shadow: 0 10px 20px 0 rgba(69, 90, 100, 0.08);
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
}
.btn:hover {
  background: #057ab1;
}

.btn.btn-transparent {
  color: #26262b;
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.btn.btn-white {
  background-color: #fff;
  color: #26262b;
}

.btn.btn-danger {
  background-color: #dc3545;
}
.btn.btn-danger:hover {
  background-color: #9e2d39;
}

.delete-btn {
  color: #dc3545;
  text-decoration: underline;
  display: block;
  text-align: end;
  cursor: pointer;
}

[type="text"].form,
[type="email"].form,
[type="number"].form,
[type="password"].form,
select.form,
textarea.form {
  font-family: inherit;
  background: #f4f7fa;
  padding: 7px 15px;
  font-size: 15px;
  display: block;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  color: #495057;
  border: 1px solid #eaeaea;
  overflow: visible;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 15px;
}

[type="text"].form:focus,
[type="email"].form:focus,
[type="password"].form:focus,
textarea.form:focus {
  background: #f4f7fa;
  color: #495057;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form:disabled {
  background: #fff !important;
  color: #26262b !important;
}

.datepicker {
  width: 100%;
  background: #f4f7fa;
  color: #26262b;
  border: 1px solid #eaeaea;
  padding: 5px;
}

.datepicker-calendar {
  border: 1px solid #eaeaea;
}

.react-datetime-picker__wrapper {
  display: flex;
  border: none !important;
  width: 100%;
}

label {
  cursor: pointer;
  display: block;
}

.loading {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.loading div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #26262b;
  animation: loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loading div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.loading div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.loading div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes loading {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.w-100 {
  width: 100%;
}
.mt-100 {
  margin-top: 100px;
}
.mb-30 {
  margin-bottom: 30px;
}

.color-green {
  color: #28a745 !important;
}
.color-red {
  color: #dc3545 !important;
}

.white-space-pre {
  white-space: pre-wrap;
}

hr {
  border: 0;
  height: 0;
  border-bottom: 1px solid #eaeaea;
  margin: 5px 0;
}
