.card-header {
  background-color: transparent;
  border-bottom: 1px solid #eaeaea;
  padding: 20px 25px;
  position: relative;
}
.card-header h3 {
  margin-bottom: 0;
  color: #26262b;
  font-size: 17px;
  font-weight: 400;
  display: inline-block;
  margin-right: 10px;
  line-height: 1.1;
  position: relative;
}
.card-header h3:after {
  content: "";
  background-color: #04a9f5;
  position: absolute;
  left: -25px;
  top: 0;
  width: 4px;
  height: 20px;
}
.card-body {
  padding: 20px;
}