header {
  background: #26262b;
  height: 50px;
  position: fixed;
  z-index: 1;
  right: 0;
  left: 0;
  padding-left: 255px;
  padding-right: 15px;
  transition: all .3s ease-out;
  -webkit-transition: all .3s ease-out;
  box-shadow: 7px 0 15px 0 rgba(38,38,43,.4);
  color: #fff;
  line-height: 50px;
}

.header-bars {
  display: inline-block;
  font-size: 18px;
  width: 50px;
  transition: all .3s ease;
}

.header-bars:hover {
  opacity: .7;
  cursor: pointer;
}

.header-user {
  float: right;
  transition: all .3s ease;
}
.header-user:hover {
  opacity: .7;
  cursor: pointer;
}

.header-username {
  float: right;
  height: 50px;
  margin-right: 10px;
  font-weight: 500;
}
.header-userimage {
  float: right;
  height: 50px;
  padding: 4px 0;
}
.header-userimage img {
  border-radius: 50%;
}

.header-user-dropdown {
  position: absolute;
  top: 55px;
  right: 15px;
  z-index: 2;
  width: 220px;
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
  float: right;
  text-align: left;
  border-radius: 3px;
  color: #26262b;
  line-height: normal;
  font-size: 15px;
  visibility: hidden;
}

.header-user-dropdown h4 {
  padding: 0 15px;
}

.header-user-dropdown p {
  margin-bottom: 10px;
  padding: 0 15px;
}

.header-user-dropdown ul {
  list-style-type: none;
  padding: 5px 0;
}

.header-user-dropdown li:hover {
  background-color: #f4f7fa;
  cursor: pointer;
}

.header-user-dropdown li a {
  color: #26262b;
  display: block;
  text-decoration: none;
  padding: 5px 20px;
}

.header-user-dropdown svg {
  width: 20px!important;
  margin-right: 10px;
}

.header-user-dropdown img {
  display: block;
  padding: 10px;
  width: 50%;
  margin: 0 auto;
  border-radius: 50%;
}
